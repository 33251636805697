import React from 'react';
import logo from './logo.svg';
import './App.css';
import AppRouter from './view/router';


function App() {
  return (
      <AppRouter />
  );
}

export default App;
